import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getQuizQuestions } from "../api/quizApi";
import "./QuizSelectorForm.css";

interface Category {
  id: number;
  name: string;
}

interface Difficulty {
  id: string;
  name: string;
}

interface FormFields {
  category: Category;
  difficulty: Difficulty;
  numberOfQuestions: number;
}

function QuizSelectorForm() {
  const navigate = useNavigate();
  const initFormFieldValues: FormFields = {
    category: categories[0],
    difficulty: difficulties[0],
    numberOfQuestions: 10,
  };

  const methods = useForm({
    defaultValues: initFormFieldValues,
  });

  const onSubmit = async (data: FormFields) => {
    navigate(
      "/quiz?category=" +
        data.category.id +
        "&difficulty=" +
        data.difficulty.id +
        "&numberOfQuestions=" +
        data.numberOfQuestions
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Paper className="form">
        <Box sx={{ width: "100%" }} p={2}>
          <Controller
            name="category"
            control={methods.control}
            rules={{ required: "Category required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={categories}
                renderInput={(params) => (
                  <TextField {...params} label="Category" error={!!error} helperText={error ? error.message : null} />
                )}
                onChange={(_, value) => {
                  onChange(value);
                  return value;
                }}
                value={value}
              />
            )}
          />
        </Box>
        <Box sx={{ width: "100%" }} p={2}>
          <Controller
            name="difficulty"
            control={methods.control}
            rules={{ required: "Difficulty required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={difficulties}
                renderInput={(params) => (
                  <TextField {...params} label="Difficulty" error={!!error} helperText={error ? error.message : null} />
                )}
                value={value}
                onChange={(_, value) => {
                  onChange(value);
                  return value;
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: "100%" }} p={2}>
          <Controller
            name="numberOfQuestions"
            control={methods.control}
            rules={{ required: "Number of questions required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Number of questions"
                variant="outlined"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
        <div className="control">
          <Button variant="contained" color="success" type="submit">
            Submit
          </Button>
        </div>
      </Paper>
    </form>
  );
}

const categories: Category[] = [
  { id: 9, name: "General Knowledge" },
  { id: 10, name: "Entertainment: Books" },
  { id: 11, name: "Entertainment: Film" },
  { id: 12, name: "Entertainment: Music" },
  { id: 13, name: "Entertainment: Musicals & Theatres" },
  { id: 14, name: "Entertainment: Television" },
  { id: 15, name: "Entertainment: Video Games" },
  { id: 16, name: "Entertainment: Board Games" },
  { id: 17, name: "Science & Nature" },
  { id: 18, name: "Science: Computers" },
  { id: 19, name: "Science: Mathematics" },
  { id: 20, name: "Mythology" },
  { id: 21, name: "Sports" },
  { id: 22, name: "Geography" },
  { id: 23, name: "History" },
  { id: 24, name: "Politics" },
  { id: 25, name: "Art" },
  { id: 26, name: "Celebrities" },
  { id: 27, name: "Animals" },
  { id: 28, name: "Vehicles" },
  { id: 29, name: "Entertainment: Comics" },
  { id: 30, name: "Science: Gadgets" },
  { id: 31, name: "Entertainment: Japanese Anime & Manga" },
  { id: 32, name: "Entertainment: Cartoon & Animations" },
];

const difficulties: Difficulty[] = [
  { id: "easy", name: "Easy" },
  { id: "medium", name: "Medium" },
  { id: "hard", name: "Hard" },
];

export default QuizSelectorForm;
