import "./App.css";
import QuizListPage from "./pages/QuizListPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import QuizPage from "./pages/QuizPage";
import Navbar from "./components/Navbar";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import { createContext, useMemo, useState } from "react";
import Footer from "./components/Footer";

const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        color: "text.primary",
        p: 3,
      }}
    >
      <BrowserRouter>
        <Navbar colorModeContext={ColorModeContext} />
        <Container maxWidth="sm" className="container">
          <Routes>
            <Route path="/" element={<QuizListPage />} />
            <Route path="/quiz" element={<QuizPage />} />
          </Routes>
        </Container>
        <Footer />
      </BrowserRouter>
    </Box>
  );
}

function ToggleColorMode() {
  const [mode, setMode] = useState<"light" | "dark">("dark");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default ToggleColorMode;
