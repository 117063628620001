import IconButton from "@mui/material/IconButton";
import "./Navbar.css";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import logo from "../assets/logo.svg";

interface NavbarProps {
  colorModeContext: React.Context<{
    toggleColorMode: () => void;
  }>;
}

function Navbar(props: NavbarProps) {
  let navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(props.colorModeContext);

  return (
    <div className="navbar">
      <img src={logo} className="logo" alt="logo" onClick={() => navigate("/")} />
      <h1 className="title" onClick={() => navigate("/")}>
        QuizzApp
      </h1>
      <div className="theme-toggler">
        <Tooltip title={`${theme.palette.mode} mode`} placement="bottom">
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default Navbar;
