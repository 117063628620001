import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import "./QuizResult.css";

interface QuizResultProps {
  correctAnswers: number;
  totalQuestions: number;
  retry: () => void;
}

function QuizResult(props: QuizResultProps) {
  const { correctAnswers, totalQuestions, retry } = props;
  let navigate = useNavigate();

  return (
    <Paper elevation={1} className="result-box">
      <h3 className="result-title">
        <b>Result</b>
      </h3>
      {correctAnswers === totalQuestions ? (
        <Alert severity="success">
          <AlertTitle>Congratulations!</AlertTitle>
          You have completed the quiz.
        </Alert>
      ) : (
        <Alert severity="error">
          <AlertTitle>Oops!</AlertTitle>
          You have answered {correctAnswers} out of {totalQuestions}.
        </Alert>
      )}

      <Stack className="control" direction="row" spacing={2}>
        <Button variant="outlined" onClick={retry}>
          Retry
        </Button>
        <Button variant="contained" onClick={() => navigate("/")}>
          New Quiz
        </Button>
      </Stack>
    </Paper>
  );
}

export default QuizResult;
