import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getQuizQuestions } from "../api/quizApi";
import QuizQuestion from "../components/QuizQuestion";
import QuizResult from "../components/QuizResult";
import { Question } from "../models/Question";

const QuizPage = () => {
  let [searchParams] = useSearchParams();
  const category = searchParams.get("category") || "";
  const difficulty = searchParams.get("difficulty") || "";
  const numberOfQuestions = parseInt(searchParams.get("numberOfQuestions") || "10");

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizLoaded, setQuizLoaded] = useState(false);

  const {
    data: questions,
    isLoading,
    isError,
    error,
  } = useQuery<Question[], Error>(
    "questions",
    async () => {
      const data: any = await getQuizQuestions(numberOfQuestions, category, difficulty);
      if (data.response_code === 1) {
        throw new Error("No results found for your search criteria. Please try again.");
      }
      data.results.map((question: Question) => {
        question.incorrect_answers.push(question.correct_answer);
        question.incorrect_answers.sort();
      });
      setQuizLoaded(true);

      return data.results;
    },
    {
      enabled: quizLoaded === false,
    }
  );

  const [answers, setAnswers] = useState<string[]>(new Array<string>(numberOfQuestions));

  const isLastQuestion = currentQuestion === numberOfQuestions;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  if (!questions || questions.length === 0) {
    return <div>No results found for your search criteria. Please try again.</div>;
  }

  const calculateCorrectAnswers = (): number => {
    return questions.reduce((acc, question, index) => {
      if (question.correct_answer === answers[index]) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const retry = () => {
    setAnswers(new Array<string>(numberOfQuestions));
    setCurrentQuestion(0);
  };

  return (
    <div>
      {isLastQuestion ? (
        <QuizResult correctAnswers={calculateCorrectAnswers()} totalQuestions={questions.length} retry={retry} />
      ) : (
        <QuizQuestion
          question={questions[currentQuestion]}
          totalQuestions={numberOfQuestions}
          currentQuestion={currentQuestion + 1}
          setAnswer={(value) => {
            setAnswers((prevAnswer) => {
              const newAnswer = [...prevAnswer];
              newAnswer[currentQuestion] = value;
              return newAnswer;
            });
            setCurrentQuestion(currentQuestion + 1);
          }}
        />
      )}
    </div>
  );
};

export default QuizPage;
