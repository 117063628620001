import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <Typography style={{ alignSelf: "flex-start" }} variant="caption">
      © 2022 cschild
    </Typography>
  );
}

export default Footer;
