import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { Question } from "../models/Question";
import Paper from "@mui/material/Paper";
import "./QuizQuestion.css";
import Typography from "@mui/material/Typography";

interface QuizQuestionProps {
  question: Question;
  totalQuestions: number;
  currentQuestion: number;
  setAnswer: (answer: string) => void;
}

function QuizQuestion(props: QuizQuestionProps) {
  const { question, totalQuestions, currentQuestion, setAnswer } = props;
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");

  function goToNextQuestion() {
    flushSync(() => {
      setAnswer(selectedAnswer);
    });
    setSelectedAnswer("");
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Paper elevation={1} className="answer-box">
      <h3 className="question-count">
        <b>{currentQuestion}</b> of
        <b> {totalQuestions}</b>
      </h3>
      <Typography variant="h5" component="div" className="question">
        {question.question}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {question.incorrect_answers.map((answer, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              onClick={() => {
                setSelectedAnswer(answer);
              }}
            >
              <Item className={`answer ${selectedAnswer === answer ? "selected" : ""}`}>{answer}</Item>
            </Grid>
          ))}
        </Grid>
      </Box>

      <div className="control">
        <Button variant="contained" color="success" onClick={goToNextQuestion} disabled={!selectedAnswer}>
          Next
        </Button>
      </div>
    </Paper>
  );
}

export default QuizQuestion;
